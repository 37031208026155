/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import StoreContext from './StoreContext';
import { useRouter } from 'next/router';
import * as Sentry from "@sentry/nextjs";

const fetchStore = async () => {
  try {
    const { data } = await axios.get(`/api/store`);
    Cookies.set('store', JSON.stringify(data.store), {
      expires: new Date(new Date().getTime() + 15 * 60 * 1000),
    });
    Cookies.set('accountId', data.store.accountId);
    Cookies.set('hostname', data.hostname);
    return data.store;
  } catch (e) {
    Sentry.captureException(e);
    return null;
  }
};

const getLocalStore = () => {
  try {
    const data = Cookies.get('store');
    if (!data || data == '') {
      return null;
    } else {
      return JSON.parse(data);
    }
  } catch (err) {
    return null;
  }
};

const getLocalUserInfo = () => {
  return Cookies.get('userInfo') ? JSON.parse(Cookies.get('userInfo')) : null;
};

const StoreProvider = (props) => {
  const [store, setStore] = useState(getLocalStore() || {});
  const [userInfo, setUserInfo] = useState(getLocalUserInfo());
  const router = useRouter();
  const [facebookPixel, setFacebookPixel] = useState(null);

  const userLogout = () => {
    setUserInfo(null);
  };

  useEffect(() => {
    async function fetch() {
      const newStore = await fetchStore();
      if (newStore == null && router.pathname != '/store-not-found') {
        router.push('/store-not-found');
        return;
      }
      setStore(newStore);
    }
    if (!store || !store._id) {
      fetch();
    }
  }, []);

  useEffect(() => {
    if (userInfo) {
      Cookies.set('userInfo', JSON.stringify(userInfo));
    } else {
      Cookies.remove('userInfo');
    }
  }, [userInfo]);

  const getCurrentDay = () => {
    if (!store.controlOpeningHours) {
      return null;
    }
    var now = new Date();
    var weekday = null;
    switch (now.getDay()) {
      case 0:
        weekday = 'sun';
        break;
      case 1:
        weekday = 'mon';
        break;
      case 2:
        weekday = 'tue';
        break;
      case 3:
        weekday = 'wed';
        break;
      case 4:
        weekday = 'thu';
        break;
      case 5:
        weekday = 'fri';
        break;
      case 6:
        weekday = 'sat';
        break;
      default:
        break;
    }
    var currentDay = store.openingHours.find((x) => x.day == weekday);
    if (!currentDay) {
      return null;
    }
    return currentDay;
  };

  const isOpen = () => {
    if (!store.controlOpeningHours) {
      return true;
    }
    var now = new Date();

    var currentDay = getCurrentDay();
    if (!currentDay) {
      return false;
    }
    var nowTime = now.toTimeString().substring(0, 5);
    if(currentDay.end < currentDay.start){
      return nowTime > currentDay.start || nowTime < currentDay.end;
    }
    return nowTime > currentDay.start && nowTime < currentDay.end;
  };

  return (
    <StoreContext.Provider
      value={{
        ...props,
        store,
        userInfo,
        setUserInfo,
        userLogout,
        isOpen,
        getCurrentDay,
        facebookPixel,
        setFacebookPixel
      }}
    >
      <>
        {props.children}
      </>
      
    </StoreContext.Provider>
  );
};

export default StoreProvider;
